





























































import Vue from 'vue'
import { NOTES_FETCH, NOTE_ADD, NOTE_DELETE } from '@/store/actions'
import { mapGetters } from 'vuex'
import NoteModel from '@/models/NoteModel'

export default Vue.extend({
  name: 'Notes',
  components: {
    // HelloWorld
  },
  mounted () {
    this.$store.dispatch(NOTES_FETCH, {})
  },
  computed: {
    ...mapGetters(['notes', 'isLoadingNotes'])
  },
  methods: {
    createNote () {
      if (this.noteText !== '') {
        this.$store.dispatch(NOTE_ADD, this.noteText)
        this.noteText = ''
      } else {
        console.warn('noteText is not allowed to be empty')
      }
    },
    deleteItem (item: NoteModel) {
      console.log(item)
      this.$store.dispatch(NOTE_DELETE, item.id)
    }
  },
  data: function () {
    return {
      search: '',
      noteText: '',
      itemsPerPage: 15,
      headers: [
        { text: this.$t('views.notes.note'), value: 'text' },
        { text: this.$t('shared.timeStamp'), value: 'timeStamp', width: '10%', align: 'center' },
        { text: this.$t('shared.action'), value: 'actions', sortable: false, width: '5%', align: 'left' }
      ]
    }
  }
})
